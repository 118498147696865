import React from 'react';

import { NextPageContext } from 'next';
import getConfig from 'next/config';

const { serverRuntimeConfig } = getConfig();
function Error({ statusCode }: { statusCode: number }) {
  return (
    <p>
      {statusCode
        ? `An error ${statusCode} occurred on server`
        : 'An error occurred on client'}
    </p>
  );
}
Error.getInitialProps = ({ err, req, res }: NextPageContext) => {
  // eslint-disable-next-line no-nested-ternary
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  // Only require Rollbar and report error if we're on the server
  if (!process.browser) {
    if (process.env.NODE_ENV === 'production') {
      // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      const Rollbar = require('rollbar');
      const rollbar = new Rollbar({
        accessToken: serverRuntimeConfig.rollbarServerToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
      });
      rollbar.error(err, req, (rollbarError: Error) => {
        if (rollbarError) {
          // eslint-disable-next-line no-console
          console.error('Rollbar error reporting failed:');
          // eslint-disable-next-line no-console
          console.error(rollbarError);
          return;
        }
        // eslint-disable-next-line no-console
        console.log('Reported error to Rollbar');
      });
    } else {
      // eslint-disable-next-line no-console
      console.log(res?.statusCode);
    }
  }
  return { statusCode };
};
export default Error;
